.navmenu {
  color: white;
  background-color: transparent !important;
  --bs-bg-opacity: 1;

  button{
    background-color: transparent;
    border: 0;
  }

  .logo{
    width: 220px;
  }

  .servicio{
    text-decoration: none;
    color: rgba(255, 255, 255, 0.55);
  }

  .servicio:hover{
    text-decoration: none;
    color: rgba(255, 255, 255, 0.75);
  }
  
  &__nav-items {
    width: 100%;
    display: flex !important;
    align-items: flex-end;
  }
}

div[aria-labelledby="basic-nav-dropdown"] {
  left: 35px !important;
}

.navmenu {
  &__nav-items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.navmenu.scroll {
  background-color: #102B5A !important;
}

.hero{
  margin-bottom: -1%;
  width: 100%;
  height: 102vh;
  object-fit: cover;
  background-color: #000;
}