.mySwiper{
  margin: 0 !important;
}

.contenedor{
  width: 100%;
  position: relative;
  overflow:hidden;
}

.imagenFondo{
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.imagenFondoAnimada{
  transform:scale(1.3);  
  transition: 3s;  
}

.imagenTexto{
  position: absolute;
  top: 40%;
  left: 10%;
  z-index: 1;
  display: grid;
  text-align: justify;
}

.imagenTexto img{
  max-width: 90%;
  max-height: 90%;
}

.imagenTexto span{
  color: white;
  min-width: 50%; 
  max-width: 90%; 
}

@media (min-width:768px) {
  .imagenTexto span{
    max-width: 60%;
  }
}

@media (max-height:650px) {
  .imagenTexto{
    top: 2%;
  }
}